var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      class: `shepherd-assignment-intro-${_vm.bundleData.bid}`,
      attrs: { flat: "" },
    },
    [
      _c("v-card-title", [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "mr-3",
                attrs: {
                  rounded: "",
                  to: {
                    name: "snapshot-assignments",
                  },
                },
              },
              [
                _c("v-icon", { staticClass: "ml-n3 secondary--text" }, [
                  _vm._v("chevron_left"),
                ]),
                _c("v-icon", { staticClass: "secondary--text" }, [
                  _vm._v("assignment"),
                ]),
              ],
              1
            ),
            _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v(_vm._s(_vm.bundleData.long_id)),
            ]),
            !_vm.isSpaceAdmin &&
            _vm.isAssignmentOverdue(_vm.bundleData.allow_handins_until)
              ? _c(
                  "div",
                  [
                    _c(
                      "span",
                      {
                        class: `caption shepherd-handin-button-${_vm.bundleData.bid}`,
                      },
                      [
                        _vm._v(
                          "Deadline has passed, no further hand-ins allowed"
                        ),
                      ]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          left: "",
                                          icon: "",
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.tour.start()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("help_outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          495645794
                        ),
                      },
                      [_c("span", [_vm._v("Show tour")])]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-tabs",
                        {
                          on: { change: _vm.tabChanged },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c("v-tab", [
                            _vm._v(
                              "Handin (" +
                                _vm._s(_vm.bundleData.handins.length) +
                                ")"
                            ),
                          ]),
                          _c("v-tab", [_vm._v("Handback")]),
                          _vm.isSpaceAdmin
                            ? _c(
                                "v-tab",
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("grading"),
                                  ]),
                                  _vm._v(" Grades "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "div",
                            { staticClass: "ma-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href: _vm.downloadURL,
                                    loading: _vm.fetchingDLToken,
                                    disabled: !_vm.downloadURL,
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-download"),
                                  ]),
                                  _vm._v(" Download "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.isSpaceAdmin &&
                          !_vm.isAssignmentOverdue(
                            _vm.bundleData.allow_handins_until
                          )
                            ? _c(
                                "span",
                                {
                                  staticClass: "ma-3 ml-0",
                                  class: `shepherd-handin-button-${_vm.bundleData.bid}`,
                                },
                                [
                                  _c("TheSnapshotAssignmentSubmitDialog", {
                                    attrs: { bundleData: _vm.bundleData },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSpaceAdmin
                            ? _c(
                                "div",
                                { staticClass: "ma-3 ml-0" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g({}, on),
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      _vm._b(
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              !_vm.isAssignmentOverdue(
                                                                _vm.bundleData
                                                                  .allow_handins_until
                                                              ),
                                                            elevation: "0",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.startGrading()
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [_vm._v("grading")]
                                                        ),
                                                        _vm._v(" Grade "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        586793503
                                      ),
                                    },
                                    [
                                      _vm.isAssignmentOverdue(
                                        _vm.bundleData.allow_handins_until
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              " Assignment deadline has passed, grading can now be started. "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "Assignment grading can be started only after the due date has passed."
                                            ),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "align-center" },
                                        [
                                          _vm.bundleData.handins &&
                                          _vm.bundleData.handins.length
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      label:
                                                        "Show only gradeable handins",
                                                      "hide-details": "auto",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.gradeableCheckboxChanged,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.gradeableCheckbox,
                                                      callback: function ($$v) {
                                                        _vm.gradeableCheckbox =
                                                          $$v
                                                      },
                                                      expression:
                                                        "gradeableCheckbox",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            [
                                              !_vm.bundleData.handins ||
                                              _vm.bundleData.handins.length ===
                                                0
                                                ? _c("div", [
                                                    _vm._v(
                                                      " No handins yet, please click the refresh button on the right to fetch them if you recently handed one in. "
                                                    ),
                                                  ])
                                                : _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      items: _vm.handins,
                                                      "item-value": "hid",
                                                      "hide-details": "auto",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.fetchHandinObjects,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item",
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.long_id
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.handin_timestamp
                                                                  )
                                                                ) +
                                                                ") "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "selection",
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.long_id
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.handin_timestamp
                                                                  )
                                                                ) +
                                                                ") "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value: _vm.handinSelect,
                                                      callback: function ($$v) {
                                                        _vm.handinSelect = $$v
                                                      },
                                                      expression:
                                                        "handinSelect",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-end",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        text: "",
                                                        outlined: "",
                                                        loading:
                                                          _vm.filesFetching,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.refreshHandins,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            left: "",
                                                          },
                                                        },
                                                        [_vm._v("refresh")]
                                                      ),
                                                      _vm._v(" Refresh "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.bundleData.handins &&
                                      _vm.bundleData.handins.length
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-3",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  class: `shepherd-assignment-files-${_vm.bundleData.bid}`,
                                                },
                                                [
                                                  _c("FileList", {
                                                    attrs: {
                                                      apiId: _vm.handinSelect,
                                                      options:
                                                        _vm.handinFileListOptions,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _vm.grade && !_vm.isSpaceAdmin
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mb-5 d-flex align-center",
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "secondary--text subtitle-1 font-weight-bold",
                                                  },
                                                  [_vm._v("Grade")]
                                                ),
                                                _vm._v(
                                                  " Your grade for this assignment is "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(_vm._s(_vm.grade))]
                                                ),
                                              ]),
                                              _c("v-spacer"),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: { "offset-y": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "visibility"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Review Corrections "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4108222840
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.applications,
                                                      function (a) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: a.aid,
                                                            attrs: {
                                                              to: {
                                                                name: "app-open",
                                                                params: {
                                                                  oid: _vm
                                                                    .$route
                                                                    .params.oid,
                                                                  sid: _vm
                                                                    .$route
                                                                    .params.sid,
                                                                  iid: _vm
                                                                    .$route
                                                                    .params.iid,
                                                                  snid: _vm
                                                                    .$route
                                                                    .params
                                                                    .snid,
                                                                  aid: a.aid,
                                                                },
                                                                query: {
                                                                  handback_bid:
                                                                    _vm
                                                                      .bundleData
                                                                      .bid,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    a.long_id
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : !_vm.bundleData.handbacks_visible &&
                                          _vm.isAssignmentOverdue(
                                            _vm.bundleData.allow_handins_until
                                          )
                                        ? _c(
                                            "div",
                                            {
                                              class: {
                                                "pb-5": _vm.isSpaceAdmin,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " This assignment is currently being graded and hand-backs / grades are not yet visible for submitters. "
                                              ),
                                            ]
                                          )
                                        : _vm.bundleData.handbacks_visible &&
                                          _vm.isAssignmentOverdue(
                                            _vm.bundleData.allow_handins_until
                                          ) &&
                                          !_vm.grade &&
                                          !_vm.isSpaceAdmin
                                        ? _c("div", [
                                            _vm._v(
                                              " You haven't received a grade for this assignment. "
                                            ),
                                          ])
                                        : !_vm.isAssignmentOverdue(
                                            _vm.bundleData.allow_handins_until
                                          ) && !_vm.isSpaceAdmin
                                        ? _c("div", [
                                            _vm._v(
                                              " The assignment deadline has not yet passed, you can continue to hand-in solutions using the Hand-In button above. "
                                            ),
                                          ])
                                        : _vm.isSpaceAdmin &&
                                          !_vm.bundleData.handbacks_visible &&
                                          !_vm.isAssignmentOverdue(
                                            _vm.bundleData.allow_handins_until
                                          )
                                        ? _c("div", [
                                            _vm._v(" No handbacks yet. "),
                                          ])
                                        : _vm._e(),
                                      _vm.isSpaceAdmin ||
                                      (_vm.bundleData.handbacks_visible &&
                                        _vm.handbacks.length > 0)
                                        ? [
                                            _c(
                                              "v-row",
                                              { staticClass: "mb-3" },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        items: _vm.handbacks,
                                                        "item-value": "hid",
                                                        "hide-details": "auto",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.fetchHandbackObjects,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.long_id
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateTimeToHuman"
                                                                      )(
                                                                        item.handin_timestamp
                                                                      )
                                                                    ) +
                                                                    ") "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.long_id
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateTimeToHuman"
                                                                      )(
                                                                        item.handin_timestamp
                                                                      )
                                                                    ) +
                                                                    ") "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1833037212
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.handbackSelect,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.handbackSelect =
                                                            $$v
                                                        },
                                                        expression:
                                                          "handbackSelect",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    class: `shepherd-assignment-files-${_vm.bundleData.bid}`,
                                                  },
                                                  [
                                                    _c("FileList", {
                                                      attrs: {
                                                        apiId:
                                                          _vm.handbackSelect,
                                                        options:
                                                          _vm.handbackFileListOptions,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "the-snapshot-assignment-grades",
                                                {
                                                  attrs: {
                                                    bid: _vm.bundleData.bid,
                                                    overdue:
                                                      _vm.isAssignmentOverdue(
                                                        _vm.bundleData
                                                          .allow_handins_until
                                                      ),
                                                    bundleData: _vm.bundleData,
                                                    startTour:
                                                      _vm.startGradingTour,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "transparent", attrs: { flat: "" } },
                    [
                      _c(
                        "v-alert",
                        {
                          class: `shepherd-assignment-description-${_vm.bundleData.bid}`,
                          attrs: { type: "info", text: "" },
                        },
                        [
                          _c("v-card-title", { staticClass: "pa-0" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Details"),
                            ]),
                          ]),
                          _vm.bundleData.description
                            ? _c("div", [
                                _vm._v(
                                  " " + _vm._s(_vm.bundleData.description) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.bundleData.allow_handins_until
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-center my-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        small: "",
                                        color: "info",
                                      },
                                    },
                                    [_vm._v("alarm")]
                                  ),
                                  _vm._v(
                                    " Due: " +
                                      _vm._s(
                                        _vm._f("dateTimeToHuman")(
                                          _vm.bundleData.allow_handins_until
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "my-2" },
                            [
                              _c("span", { attrs: { left: "" } }, [
                                _vm._v("Handback visibility:"),
                              ]),
                              _c("v-icon", { attrs: { color: "info" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.bundleData.handbacks_visible
                                      ? "visibility"
                                      : "visibility_off"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.bundleData.creation_timestamp
                            ? _c("div", { staticClass: "mt-1 mb-4 caption" }, [
                                _c("span", [_vm._v("Created")]),
                                _vm._v(
                                  " : " +
                                    _vm._s(
                                      _vm._f("dateTimeToHuman")(
                                        _vm.bundleData.creation_timestamp
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.isSpaceAdmin &&
                          _vm.bundleData.bundle_type_name ===
                            _vm.distributionReasons.ASSIGNMENT
                            ? _c("TheBundleEditDialog", {
                                attrs: {
                                  bundleData: _vm.bundleData,
                                  showTooltip: false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "dialogactivator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("edit")]
                                              ),
                                              _vm._v(" Edit "),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1440260550
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-subheader", [
                        _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [
                            _vm._v(
                              "Files (" +
                                _vm._s(_vm.bundleData.objects.files.length) +
                                ")"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-list",
                        {
                          staticClass: "pa-0 transparent",
                          attrs: { dense: "" },
                        },
                        _vm._l(_vm.bundleData.objects.files, function (file) {
                          return _c(
                            "v-list-item",
                            {
                              key: file,
                              staticClass: "pa-0",
                              staticStyle: { "max-height": "5px" },
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-file-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "subtitle-2" },
                                    [_vm._v(_vm._s(file.slice(6)))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c("v-subheader", [
                        _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [
                            _vm._v(
                              "Applications (" +
                                _vm._s(
                                  _vm.bundleData.objects.applications.length
                                ) +
                                ")"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-list",
                        {
                          staticClass: "pa-0 transparent",
                          attrs: { dense: "" },
                        },
                        _vm._l(
                          _vm.bundleData.objects.applications,
                          function (application) {
                            return _c(
                              "v-list-item",
                              {
                                key: application,
                                staticClass: "pa-0",
                                staticStyle: { "max-height": "5px" },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("desktop_windows"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        staticClass:
                                          "secondary--text subtitle-2",
                                      },
                                      [_vm._v(_vm._s(application))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "50%", persistent: "" },
          model: {
            value: _vm.errDialog,
            callback: function ($$v) {
              _vm.errDialog = $$v
            },
            expression: "errDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Permission error")]),
              _c(
                "v-card-text",
                [
                  _vm._v(" You have "),
                  _c(
                    "v-chip",
                    { attrs: { outlined: "", small: "", color: "warning" } },
                    [_vm._v("VIEWER")]
                  ),
                  _vm._v(
                    " role in this instance, thus does not have the permission to view this. We will guide you back to and instance where you have at least "
                  ),
                  _c(
                    "v-chip",
                    { attrs: { outlined: "", small: "", color: "primary" } },
                    [_vm._v("EDITOR")]
                  ),
                  _vm._v(" role. "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }